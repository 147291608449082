<template>
  <div class="list-filter">
    <div class="bg-white pt-4 pb-4 ">
      <div class="col-md-12">
        <div class="row">
          <input type="hidden" v-model="forAdd.id">
          <div class="col-12 col-md-3">
            <div role="group" class="form-group">
              <label for="code" class="d-block">Название</label>
              <div><input id="name" type="text" placeholder="Название" class="form-control" v-model="forAdd.name" required></div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div role="group" class="form-group">
              <label for="code" class="d-block">Code</label>
              <div><input id="code" type="text" placeholder="Code" class="form-control" v-model="forAdd.code"></div>
            </div>
          </div>

          <div class="col-12 col-md-3">
            <div role="group" class="form-group">
              <label for="code" class="d-block">Активность</label>
              <div><input id="active" type="checkbox" placeholder="Активность" v-model="forAdd.active"></div>
            </div>
          </div>
        </div>

        <div class="row">
        <div class="col-12 col-md-3" v-if="!noFile">
          <div role="group" class="form-group">
            <label for="code" class="d-block">Картинка</label>
            <upload-file
              v-model="media"
              :options="photoOptions"
              @input="fileLoaded"
            />
          </div>
        </div>
        </div>

        <div class="btn btn-success" @click="addLike">Сохранить</div>
      </div>
    </div>
  </div>
</template>
<script>
import { EDIT_LIKE } from '@/core/services/store/likes.module';
import UploadFile from '@/view/content/widgets/FileDropSingle';
export default {
  name: 'AddForm',
  components: { UploadFile },
  props: {
    forAddProp: {},
    noFile: Boolean,
  },
  data() {
    return {
      forAdd: {
        id:false,
        name:'',
        code:'',
        pct:'',
        active:false,
      },
      photoOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        maxFilesize: 20,
        maxFiles: 1,
        acceptedFiles: 'image/*',
      },
      media: '',
    }
  },
  watch : {
    forAddProp:function() {
      this.setFromProp();
    },
  },
  mounted() {
    this.setFromProp();
  },
  methods: {
    async addLike() {
      if(this.forAdd.name && this.forAdd.code) {
        let data = {
          "Code": this.forAdd.code,
          "Name": this.forAdd.name,
          "IsActive": this.forAdd.active,
        };
        if(this.forAdd.pct)
          data.MediaGUID = this.forAdd.pct;
        if(this.forAdd.id)
          data.ID = this.forAdd.id;

        let res = await this.$store.dispatch(EDIT_LIKE, data);
        if(res.data?.data?.Success) {
          this.$emit('onAdded');

          if(!this.forAddProp?.id)
            this.$router.push('/general/likes');
        }
        else {
          let error = res.data.error?.message;
          if(error)
            alert(error);
        }

      }
      else
        alert('Заполните поля');
    },
    setFromProp() {
      if(this.forAddProp?.id)
        this.forAdd = this.forAddProp;
    },
    fileLoaded(data) {
      this.forAdd.pct = data?.guid;
    },
  },
}
</script>