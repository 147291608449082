<template>
  <div>
    <h1>Создание новой реакции</h1>
    <likes-add />
  </div>
</template>
<script>
import likesAdd from './AddForm';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
export default {
  name: 'LikeNew',
  components: { likesAdd },
  beforeMount() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Реакции', route: { name: 'likes' } },
      { title: 'Создать' },
    ]);
  },
}
</script>